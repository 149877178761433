import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import UserCard from "../components/UserCard";
import { Link } from "react-router-dom";

const catsInfo = [
  { name: "cat1", comment: "Bondok looking through the window" },
];

const iconAction = () => {
  return (
    <Image src="/images/cary/magenta-heart.svg" style={{ width: "2rem" }} />
  );
};

const Cary = () => {
  return (
    <Container fluid>
      <Row className="my-2 align-items-center">
        <Col>
          <p className="display-5 fw-bold text-center">
            Cary, North Carolina (2023)
          </p>
        </Col>
      </Row>
      <Row>
        {catsInfo.map((cat, index) => {
          return (
            <Col
              key={index}
              className="d-flex justify-content-center align-items-stretch"
            >
              <UserCard
                cardText={cat.comment}
                imgUrl={`images/cary/${cat.name}.jpg`}
                action={iconAction()}
              />
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col className="text-center">
          <Link to="/palma">
            <Button variant="outline-primary px-4 m-2 col-5">Next</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Cary;
