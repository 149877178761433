import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import UserCard from "../components/UserCard";
import { Link } from "react-router-dom";

const catsInfo = [
  { name: "cat1", comment: "Basboka children" },
  { name: "cat2", comment: "Baskouta, I think" },
  { name: "cat3", comment: "Baskouta Junior" },
  { name: "cat4", comment: "Basbousa playing" },
  { name: "cat5", comment: "Basboussa 1" },
  { name: "cat6", comment: "Basbousaa 2" },
  { name: "cat7", comment: "Bondok ?" },
  { name: "cat8", comment: "Baskota Junior in the kitchen" },
  { name: "cat9", comment: "Baskota Junior and Basbousa" },
  { name: "cat10", comment: "Mother and two children" },
  { name: "cat11", comment: "Bondok and Basbousa" },
  { name: "cat12", comment: "Bondok at the reception" },
  { name: "cat13", comment: "Baskota Senior" },
  { name: "cat14", comment: "Bondok after hair cut 1" },
  { name: "cat15", comment: "Bondok after hair cut 2" },
  { name: "cat16", comment: "Baskota Junior and her sister" },
  { name: "cat17", comment: "Bondok and Baskota Junior" },
  { name: "cat18", comment: "Baskota Junior in the morning" },
  { name: "cat19", comment: "Baskota Junior waiting for dry food" },
  { name: "cat20", comment: "Baskota Junior in the Living" },
];

const iconAction = () => {
  return <Image src="/images/penta/red-heart.svg" style={{ width: "2rem" }} />;
};

const Penta = () => {
  return (
    <Container fluid>
      {/* <Row
        className="my-2 align-items-center bg-info"
        style={{ height: "200px" }}
      >
        <Col className="bg-danger mx-2 border border-primary border-5 rounded shadow">
          <p className="display-5 text-center">Penta Villas</p>
        </Col>
      </Row> */}
      <Row className="my-2 align-items-center">
        <Col>
          <p className="display-5 fw-bold text-center">
            Penta Villas (2021-2023)
          </p>
        </Col>
      </Row>
      <Row>
        {catsInfo.map((cat, index) => {
          return (
            <Col
              key={index}
              className="d-flex justify-content-center align-items-stretch"
            >
              <UserCard
                cardText={cat.comment}
                imgUrl={`images/penta/${cat.name}.jpg`}
                action={iconAction()}
              />
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col className="text-center">
          <Link to="/dip2">
            <Button variant="outline-primary px-4 m-2 col-5">Next</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Penta;
