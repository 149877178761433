import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import UserCard from "../components/UserCard";
import { Link } from "react-router-dom";

const catsInfo = [
  { name: "cat1", comment: "Cute girl and mother" },
  { name: "cat2", comment: "Cute boy which got lost" },
  { name: "cat3", comment: "The whole family looking ahead" },
  { name: "cat4", comment: "Rest in the shade and trying to feed" },
  { name: "cat5", comment: "Brother and sister" },
  { name: "cat6", comment: "Son looking through the kitchen window" },
  { name: "cat7", comment: "Mom looking through the kitchen window" },
  { name: "cat8", comment: "Orange Cat" },
  { name: "cat9", comment: "Mom with children at lunch" },
  { name: "cat10", comment: "A cute one" },
  { name: "cat11", comment: "Sleeping under the shade" },
  { name: "cat12", comment: "Ezz Cat" },
];

const iconAction = () => {
  return <Image src="/images/palma/blue-heart.svg" style={{ width: "2rem" }} />;
};

const Palma = () => {
  return (
    <Container fluid>
      <Row className="my-2 align-items-center">
        <Col>
          <p className="display-5 fw-bold text-center">
            Palma Springs (2012-2021)
          </p>
        </Col>
      </Row>
      <Row>
        {catsInfo.map((cat, index) => {
          return (
            <Col
              key={index}
              className="d-flex justify-content-center align-items-stretch"
            >
              <UserCard
                cardText={cat.comment}
                imgUrl={`images/palma/${cat.name}.jpg`}
                action={iconAction()}
              />
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col className="text-center">
          <Link to="/penta">
            <Button variant="outline-primary px-4 m-2 col-5">Next</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Palma;
