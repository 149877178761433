import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";

const UserCard = ({ cardText, imgUrl, action }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card className="shadow m-3" style={{ width: "18rem" }}>
        <Card.Img
          variant="top fluid"
          src={imgUrl}
          alt="image not found"
          // onClick={handleClick}
          onClick={handleShow}
        />
        <Card.Body className="d-flex flex-column justify-content-end align-items-center">
          <Card.Title></Card.Title>
          <Card.Text>{cardText}</Card.Text>
          <Button variant="outline-primary col-8" onClick={handleShow}>
            {action}
          </Button>
        </Card.Body>
      </Card>

      {/* Modal to display larger image  size="xl" */}
      <Modal fullscreen={true} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>A bigger photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img src={imgUrl} className="img-fluid" alt="" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserCard;
