import "./App.css";
import { Route, Routes } from "react-router-dom";
import Palma from "./routes/Palma";
import Penta from "./routes/Penta";
import Dip2 from "./routes/Dip2";
import Cary from "./routes/Cary";
import NavBar from "./components/NavBar";
import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";


function App() {
  return (
    <Container fluid>
      <Routes>
        <Route path="/" element={<NavBar />}>
          <Route path="/" element={<Palma />} />
          <Route path="palma" element={<Palma />} />
          <Route path="penta" element={<Penta />} />
          <Route path="dip2" element={<Dip2 />} />
          <Route path="cary" element={<Cary />} />
          <Route path="*" element={<Palma />} />
          </Route>
      </Routes>
    </Container>
  );
}

export default App;
