import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Outlet } from "react-router-dom";
import Image from "react-bootstrap/Image";

const NavBar = () => {
  return (
    <>
      <Navbar expand="sm" className="bg-body-tertiary shadow">
        <Container>
          <Navbar.Brand href="/">
            <Image src="/images/cat-o.svg" style={{ width: "2.5rem" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/palma">Palma Springs</Nav.Link>
              <Nav.Link href="/penta">Penta Villas</Nav.Link>
              <Nav.Link href="/dip2">DIP 2</Nav.Link>
              <Nav.Link href="/cary">Cary</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default NavBar;
